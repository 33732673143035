import Contact from '@solid-ui-blocks/CallToAction/Block02'
import CustomHeader from "../../../blocks/CustomHeader";
import Divider from '@solid-ui-components/Divider'
import Faq from '@solid-ui-blocks/Faq/Block01'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import Features from '@solid-ui-blocks/Features/Block06'
import FeaturesTopImage from "../../../blocks/FeaturesTopImage";
import FeaturesTwoCols from '../../../blocks/FeaturesTwoCols/FeaturesTwoCols'
import Footer from '../../../blocks/Footer/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Layout from '../../../@elegantstack/solid-ui-layout/src/Layout/Layout'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import React from 'react'
import Seo from '@solid-ui-components/Seo'
import Team from '@solid-ui-blocks/Hero/Block03'
import TrainingIcons from '../../../blocks/TrainingIcons/TrainingIcons'
import Separator from '../../../blocks/Separator/Separator'
import Arena from '../../../blocks/Arena/Arena'
import styles from './_styles'
import theme from './_theme'
import {Container} from 'theme-ui'
import {graphql} from 'gatsby'
import {normalizeBlockContentNodes} from '@blocks-helpers'

const Services02 = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout theme={theme} {...props}>
            <Seo title='Training'/>
            {/* Modals */}
            <ModalWithTabs content={content['contact']}/>
            {/* Blocks */}
            <CustomHeader content={content['header-dark-en']}/>
            <Container title=''variant='full' sx={styles.heroContainer}>
                <Hero content={content['hero']} reverse/>
            </Container>
            <Container title=''variant='full' sx={styles.orangeSubHeader}>
                <TrainingIcons content={content['training-icons']}/>
            </Container>
            <Divider space='5'/>
            <Container title=''variant='full' sx={styles.featuresContainer}>
                <FeaturesTopImage content={content['features']}/>
                <FeaturesTwoCols content={content['inicio']}/>
            </Container>
            <Divider space='5'/>

            <Divider space='5'/>
            <Container title=''variant='wide' sx={styles.bloquesContainer}>
                <Faq content={content['bloque1']}/>
                <Divider space='4'/>
                <Faq content={content['bloque2']}/>
                <Divider space='4'/>
                <Faq content={content['bloque3']}/>
            </Container>

            <Divider space='4'/>

            <Container title=''variant='full' sx={styles.modalidadesContainer}>
                <Features content={content['modalidades']}/>
            </Container>

            <Divider space='5'/>
            <Divider space='5'/>

            {/*<FeatureOne content={content['hispasec-arena']}/>*/}

            <Arena content={content['hispasec-arena']}/>

            <Divider space='5'/>
            <Divider space='5'/>

            <FeatureOne content={content['feature-one']}/>
            <Divider space='5'/>
            <FeatureTwo content={content['feature-two']} reverse/>
            <Divider space='5'/>
            <FeatureTwo content={content['feature-three']} />

            <Divider space='5'/>
            <Divider space='5'/>
            <Contact content={content['get-started']}/>
            <Divider space='6'/>
            <Footer content={content['footer-en']}/>
        </Layout>
    )
}

export const query = graphql`
  query innerpageSiteEducationBlockContentEn {
    allBlockContent(
      filter: { page: { in: ["site/education-en", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Services02
